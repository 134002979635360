import { registerApplication, start } from 'single-spa';
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout';
import microfrontendLayout from './microfrontend-layout.html';
import {
  getAccountInfo,
  getCurrency,
  getUserDetails,
  getTranslations,
  getTenantDetails,
  getPermissions,
  getFeatureFlags,
} from '@sales-i/utils';

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
getUserDetails();
getFeatureFlags();
getCurrency();
getTranslations({ locale: (localStorage.getItem('locale') || 'en-gb') });
getTenantDetails();
getPermissions();
getAccountInfo();
start();
